html {
  background: rgb(0,0,0);
  background: linear-gradient(138deg, rgba(0,0,0,1) 24%, rgba(13,13,22,1) 40%, rgba(56,54,54,1) 68%, rgba(56,54,54,1) 94%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}